import type { WNavItem } from '@zola/svc-web-api-ts-client';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import { NavItem } from './types';

const registrySignupLink = '/onboard/new';

const getRegistryLink = (link: string, userContext: UserContext | null) =>
  userContext && userContext.has_registry ? link : registrySignupLink;

const otherResources: WNavItem[] = [
  { title: 'Registry Benefits', path: '/wedding-registry/benefits' },
  { title: 'How Zola Registry Works', path: '/wedding-registry/how-it-works' },
  { title: 'Add a Gift from Another Store', path: '/wedding-registry/add-from-other-stores' },
];

const registryAccountLinks = (userContext: UserContext | null): NavItem[] => [
  {
    title: 'Overview',
    subtext: 'See your progress and next steps',
    path: getRegistryLink('/registry/overview', userContext),
    icon: 'overview',
    className: 'selenium__registry-overview-link',
    target: '_self',
  },
  {
    title: 'Manage Registry',
    subtext: 'Edit how your registry looks to guests',
    path: getRegistryLink('/my-registry', userContext),
    icon: 'manage-registry',
    className: 'selenium__registry-manage-link',
  },
  {
    title: 'Gift Tracker',
    subtext: 'View, exchange, and ship your gifts',
    path: getRegistryLink('/registry/gift-tracker', userContext),
    icon: 'gift-tracker',
    className: 'selenium__registry-gift-tracker-link',
    target: '_self',
  },
  {
    title: 'Your Registry Checklist',
    subtext: 'Cover all the essentials',
    path: getRegistryLink('/registry/checklist', userContext),
    icon: 'registry-checklist',
    className: 'selenium__registry-checklist-link',
    target: '_self',
  },
  {
    title: 'Your Quick-Add Packs',
    newTag: true,
    subtext: 'Fill your registry in a flash',
    path: getRegistryLink('/registry/quick-add-packs', userContext),
    icon: 'nav-add',
    className: 'selenium__registry-starter-packs-link',
    newBadge: true,
  },
  {
    title: 'Your Perks',
    subtext: 'Get exclusive offers and bonus gifts',
    path: getRegistryLink('/wedding-registry/perks', userContext),
    icon: 'your-perks',
    className: 'selenium__registry-perks-link',
  },
  {
    title: 'Other Resources',
    children: otherResources,
    other: true,
    className: 'secondary-nav__other-section__link',
  },
];

export default registryAccountLinks;
