import type { WWeddingShopNavView, WNavItem } from '@zola/svc-web-api-ts-client';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import { NavItem } from './types';

class SecondaryNavWeddingShopData {
  userContext: UserContext | null;

  weddingShopCategoryItems?: WWeddingShopNavView;

  constructor(userContext: UserContext | null, weddingShopCategoryItems?: WWeddingShopNavView) {
    this.userContext = userContext;
    this.weddingShopCategoryItems = weddingShopCategoryItems;
    this.createSubCategory = this.createSubCategory.bind(this);
  }

  getItems(): NavItem[] {
    return this.weddingShopNavItemsNew();
  }

  createSubCategory(categories: WNavItem[]): NavItem[] {
    return categories.map(category => {
      const giftsAndFavors = category.path === '/shop/category/wedding-gifts-favors';
      const intimates = category.path === '/shop/category/wedding-intimates';
      const partySupplies = category.path === '/shop/category/party-supplies-decor';
      const beautyAndWellness = category.path === '/shop/category/health-wellness';
      const id = category.path?.substring(category.path.lastIndexOf('/') + 1);
      return {
        id,
        title: category.title,
        path: category.path,
        dropdownItems: category.children,
        showCaret: false,
        dockRight: giftsAndFavors || intimates || partySupplies || beautyAndWellness,
        parent: 'Wedding Boutique',
      };
    });
  }

  weddingShopNavItemsNew(): NavItem[] {
    const weddingShopNav: NavItem[] = [
      {
        id: 'new-arrivals',
        title: 'New Arrivals',
        path: '/shop/collection/wedding-shop-new-arrivals',
        parent: 'Wedding Boutique',
      },
    ];
    if (this.weddingShopCategoryItems && this.weddingShopCategoryItems.categories) {
      return weddingShopNav.concat(
        this.createSubCategory(this.weddingShopCategoryItems.categories)
      );
    }
    return [];
  }
}

export default SecondaryNavWeddingShopData;
