const createEnvironmentFlags = (development = false, staging = false, production = false) => ({
  development,
  staging,
  production,
});

// export const EXAMPLE_FLAG = 'example-flag';
export const REGISTRY_PDP = 'registry-pdp';

/**
 * Example where EXAMPLE_FLAG is a string constant:
 * [EXAMPLE_FLAG]: createEnvironmentFlags(true, true, false),
 */
const flags = {
  // [EXAMPLE_FLAG]: createEnvironmentFlags(true, true, false),
  [REGISTRY_PDP]: createEnvironmentFlags(true, true, false),
  navSsr: createEnvironmentFlags(true, true, true),
};

type Environments = 'development' | 'staging' | 'production';
const getEnvironment = (): Environments => {
  const zolaEnv: string | undefined =
    (typeof window !== 'undefined' && window.zola && window.zola.env) || process.env.ZOLA_ENV;
  switch (zolaEnv) {
    case 'development':
    case 'dev':
      return 'development';
    case 'staging':
    case 'stage':
      return 'staging';
    case 'production':
    case 'prod':
      return 'production';
    default:
      return 'production';
  }
};

type FeatureFlag = keyof typeof flags;

const get = (flag: FeatureFlag): boolean => {
  if (flags[flag]) {
    return Boolean(flags[flag][getEnvironment()]);
  }
  return false;
};

export default {
  get,
  getEnvironment,
};
