import type { WNavItem } from '@zola/svc-web-api-ts-client';

export interface NavItem extends WNavItem {
  id?: string; // wedding shop
  identifier?: string; // registry
  parent?: string;
  showCaret?: boolean;
  dockRight?: boolean;
  centerDropdown?: boolean;
  isActive?: boolean;
  subtext?: string;
  newTag?: boolean;
  newBadge?: boolean;
  other?: boolean;
  icon?: string;
  className?: string;
  target?: string;
  activeRegex?: RegExp;
  multipleSubCategories?: boolean;
  badgeText?: string;
  dropdownItems?: WNavItem[];
}
export enum SecondaryNavType {
  REGISTRY = 'registry',
  WEBSITE = 'website',
  PAPER = 'invites',
  GUEST_LIST = 'guestList',
  WEDDING_BOUTIQUE = 'weddingBoutique',
  ALBUMS = 'albums',
}
