import type { WNavItem } from '@zola/svc-web-api-ts-client';

import { UserContext } from '@zola-helpers/client/dist/es/@types';

import registryAccountLinks from './registryAccountLinks';
import { NavItem } from './types';

class SecondaryNavRegistryData {
  userContext: UserContext | null;

  isShopHolidaySeason?: boolean;

  categories: WNavItem[];

  brands: WNavItem[];

  constructor(
    userContext: UserContext | null,
    registryCategoryItems: { categories?: WNavItem[]; brands?: WNavItem[] } | undefined,
    isShopHolidaySeason: boolean
  ) {
    this.userContext = userContext;
    this.isShopHolidaySeason = isShopHolidaySeason;

    if (
      registryCategoryItems &&
      registryCategoryItems.categories &&
      registryCategoryItems.categories.length > 0
    ) {
      this.categories = registryCategoryItems.categories;
    } else {
      this.categories = [];
    }

    if (
      registryCategoryItems &&
      registryCategoryItems.brands &&
      registryCategoryItems.brands.length > 0
    ) {
      this.brands = registryCategoryItems.brands;
    } else {
      this.brands = [];
    }
  }

  getItems(): NavItem[] {
    if (this.categories == null) {
      return [];
    }
    const currentPath = window.location.pathname;
    const cashFundsItem = {
      identifier: 'cash-funds',
      title: 'Cash Funds',
      path: '/shop/honeymoon-cash-funds',
      parent: 'Registry',
    };
    Object.assign(cashFundsItem, {
      showCaret: false,
      dockRight: true,
      centerDropdown: false,
      children: [
        {
          identifier: 'honeymoon-cash-funds',
          title: 'Cash & Honeymoon Funds',
          path: '/shop/honeymoon-cash-funds',
        },
        {
          identifier: 'gift-cards',
          title: 'Gift Cards',
          path: '/shop/category/gift-cards',
        },
      ],
    });
    let items = this.navItems().concat(
      // remove first cash item b/c BE returns an item w/ no children
      this.createSubCategory(this.categories)
        .filter(item => item.title !== 'Cash Funds')
        .concat(cashFundsItem)
    );
    // If user does not have a registry remove registry tab
    if (!this.userContext?.has_registry) {
      items = items.slice(1);
    }
    return this.setActiveItem(items, currentPath);
  }

  setActiveItem(items: NavItem[], currentPath: string): NavItem[] {
    return items.map(item => ({
      ...item,
      isActive: Boolean(item.path && currentPath.indexOf(item.path) > -1),
    }));
  }

  createSubCategory(categories: NavItem[]): NavItem[] {
    return categories.map(category => {
      return {
        identifier: category.path?.replace('/shop/category/', '') || '',
        title: category.title,
        path: category.path,
        showCaret: false,
        children: category.children,
        centerDropdown: true,
        parent: 'Registry',
      };
    });
  }

  createBrandsDropdownItems(brands: NavItem[]): WNavItem[] {
    const featuredBrands = brands.slice(0, 7);
    const featuredBrandsItems: WNavItem[] = featuredBrands.map((featureBrand: NavItem) => ({
      title: featureBrand.title,
      path: featureBrand.path,
    }));
    const path = '/shop/brands';
    featuredBrandsItems.push({ title: 'View All', path });
    return [
      {
        title: 'Featured Brands',
        path,
        children: featuredBrandsItems,
      },
      {
        title: 'Partner Stores',
        path,
        children: this.partnerStoreItems(),
      },
    ];
  }

  navItems(): NavItem[] {
    return [
      {
        identifier: 'your-registry',
        activeRegex: /^\/(wedding-registry|registry)\//,
        title: 'Your Registry',
        path: '/registry/overview',
        target: '_self',
        showCaret: true,
        children: this.registryItems(),
        parent: 'Registry',
      },
      {
        identifier: 'new-arrivals',
        title: 'New Arrivals',
        path: '/shop/collection/new-arrivals',
        parent: 'Registry',
      },
      {
        identifier: 'collections',
        activeRegex: /^\/shop\/collection\//,
        title: 'Collections',
        path: '/shop/gift-collections/starter-collections',
        children: this.starterCollectionItems(),
        showCaret: false,
        parent: 'Registry',
        icon: this.isShopHolidaySeason
          ? `${process.env.ASSET_PATH}/static-baby/images/icons/gift.svg`
          : undefined,
      },
      {
        identifier: 'brands',
        activeRegex: /^\/shop\/brand\//,
        title: 'Brands',
        path: '/shop/brands',
        target: '_self',
        children: this.createBrandsDropdownItems(this.brands),
        showCaret: false,
        multipleSubCategories: true,
        parent: 'Registry',
      },
    ];
  }

  partnerStoreItems(): NavItem[] {
    return [
      {
        title: 'Crate and Barrel',
        path: '/shop/partner-boutique/crate-barrel',
      },
      {
        title: "Macy's",
        path: '/shop/partner-boutique/macys',
        badgeText: 'new',
      },
    ];
  }

  starterCollectionItems(): WNavItem[] {
    if (this.isShopHolidaySeason) {
      return [
        { title: 'Registry Essentials', path: '/shop/collection/zola-registry-essentials' },
        { title: 'Ultimate Kitchen Essentials', path: '/shop/collection/top-kitchen-essentials' },
        { title: 'Entertaining Must-Haves', path: '/shop/collection/easy-entertaining' },
        { title: '2022 Most Wanted Gifts', path: '/shop/collection/most-wanted' },
        { title: 'Gifts Under $100', path: '/shop/collection/gifts-under-100' },
        { title: 'Gifts Under $200', path: '/shop/collection/gifts-under-200' },
        {
          title: 'All Of Your Holiday Must-Haves',
          path: '/shop/collection/all-of-your-holiday-must-haves',
        },
      ];
    }
    return [
      {
        title: 'Most Popular Gifts',
        path: '/shop/collection/top-registry-gifts-this-week',
      },
      {
        title: 'Registry Essentials',
        path: '/shop/collection/zola-registry-essentials',
      },
      {
        title: 'Ultimate Kitchen Essentials',
        path: '/shop/collection/top-kitchen-essentials',
      },
      {
        title: 'Entertaining Must-Haves',
        path: '/shop/collection/easy-entertaining',
      },
      { title: 'Build Your Bar', path: '/shop/collection/build-your-bar' },
      {
        title: 'Gifts Guests Love to Give',
        path: '/shop/collection/guests-favorites',
      },
      { title: 'Gifts Under $100', path: '/shop/collection/gifts-under-100' },
    ];
  }

  registryItems(): NavItem[] {
    return registryAccountLinks(this.userContext);
  }
}

export default SecondaryNavRegistryData;
