import styled from '@emotion/styled';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const RootNav = styled.div<{ overlay: boolean }>`
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 40;
  background-color: ${({ overlay }) => (overlay ? 'transparent' : 'inherit')}!important;
`;

export const NavContainerSSG = styled.div<{ hidden: boolean; overlay: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  height: ${({ overlay }) => (overlay ? '0' : '56px')};
  ${MEDIA_QUERY.DESKTOP} {
    height: ${({ overlay }) => (overlay ? '56px' : '136px')};
  }
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    height: 136px;
    margin-bottom: -1px; // prevents double border when used with Page component
  }
`;
